const staticMerch = [
	{
		id: 'blissful-sunflower-black-tee',
		name: 'Blissful Sunflower Black Tee',
		description: `Matt Deasy designed \'Blissful Sunflower\' on Black AS Colour Staple Tee.`,
		price: [ 30, 35 ],
		image: [ { src: process.env.PUBLIC_URL + '/blissful-sunflower-black-tee.jpg', caption: ''} ],
		params: [ {
			type: 'size',
			option: 'T-Shirt Size',
			values: [
				'XXL',
				'XL',
				'L',
				'M',
				'S',
			]
		}],
		weight: { base: 0.16, S: 0, M: 0.03, L: 0.05	, XL: 0.08, XXL: 0.1},
		tax: true,
		public: true,
	},

	{
		id: 'blissful-sunflower-tiedye-tee',
		name: 'Blissful Sunflower Tiedye Tee',
		description: `Matt Deasy designed \'Blissful Sunflower\' on Black/Grey Tiedye Port & Company Tee.`,
		price: [ 40, 45 ],
		image: [ { src: process.env.PUBLIC_URL + '/blissful-sunflower-tiedye-tee.jpg', caption: ''} ],
		params: [ {
			type: 'size',
			option: 'T-Shirt Size',
			values: [
				'XXL',
				'XL',
				'L',
				'M',
				'S',
			]
		}],
		weight: { base: 0.16, S: 0, M: 0.03, L: 0.05	, XL: 0.08, XXL: 0.1},
		tax: true,
		public: true,
	},

	{
		id: 'retro-beanie',
		name: 'Retro Beanie',
		description: `4ZZZ classic logo on AS Cable Beanie.`,
		price: [ 30, 35 ],
		image: [ { src: process.env.PUBLIC_URL + '/retro-beanie.jpg', caption: ''} ],
		params: [ {
			type: 'colour',
			option: 'Colour',
			values: [
				'black',
				'petrol-blue',
			]
		}],
		weight: { base: 0.14},
		tax: true,
		public: true,
	},

	{
		id: '4zzz-heart-coasters',
		name: '4ZZZ Heart Coaster',
		description: "Keep your sufaces protected with a lovely 4ZZZ coaster!",
		price: [ 5, 7 ],
		image: [ { src: process.env.PUBLIC_URL + '/4zzz-heart-coasters.jpg', caption: ''} ],
		weight: { base: 0 },
		tax: true,
		public: true,
	},
	{
		id: 'radio-against-the-machine-tee',
		name: 'Radio Against the Machine Tee',
		description: `Last chance to pick up George Levi's awesome illustration celebrating Radio Against the Machine, printed on AS Colour black staple. LIMITED STOCK!!`,
		price: [ 30, 35 ],
		image: [ { src: process.env.PUBLIC_URL + '/radio-against-the-machine-tee.jpg', caption: ''} ],
		params: [ {
			type: 'size',
			option: 'T-Shirt Size',
			values: [
				'XXL',
				'XL',
				'L',
				'M',
				'S',
			]
		}],
		weight: { base: 0.16, S: 0, M: 0.03, L: 0.05	, XL: 0.08, XXL: 0.1},
		tax: true,
		public: true,
	},

	{
		id: 'on-a-different-frequency-tote',
		name: 'On A Different Frequency Tote',
		image: [ { src: process.env.PUBLIC_URL + '/on-a-different-frequency-tote.jpg', caption: ''} ],
		description: `Last chance to pick up George Levi's loving portrait of Lou 'On A Different Frequency'. LIMITED STOCK!!`,
		price: [ 20, 25 ],
		weight: { base: 0.16},
		tax: true,
		public: true,
	},
	

	{
		id: 'cool-banana-caps',
		name: 'Cool Banana Caps',
		description: `Cool Banana Caps in a light-weight recycled nylon in a range of colours.`,
		price: [ 30, 35 ],
		image: [ { src: process.env.PUBLIC_URL + '/cool-banana-caps.jpg', caption: ''} ],
		params: [ {
			type: 'colour',
			option: 'Colour',
			values: [
				'red',
				'light-blue',
				'olive-green',
				'black',
				'cream',
			]
		}],
		weight: { base: 0.14},
		tax: true,
		public: true,
	},
	{
		id: 'matt-mawson-banana-natural-tee',
		name: 'Matt Mawson Banana Natural Tee',
		description: `Matt Mawson's iconic banana is back and in colour. On a soft natural cotton tee.`,
		price: [ 30, 35 ],
		image: [ { src: process.env.PUBLIC_URL + '/matt-mawson-banana-natural-tee.jpg', caption: ''} ],
		params: [ {
			type: 'size',
			option: 'T-Shirt Size',
			values: [
				'XXL',
				'XL',
				'L',
				'M',
				'S',
			]
		}],
		weight: { base: 0.16, S: 0, M: 0.03, L: 0.05	, XL: 0.08, XXL: 0.1},
		tax: true,
		public: true,
	},
	{
		id: 'native-bee-green-long-sleeve-tee',
		name: 'Native Bee Long Sleeve Tee',
		description: `George Levi designed us a long sleeve homage to Brisbane's native bee the Tetragonula carbonaria. In bright magenta on a rich green, emblazened with typography on the front and sides and graphic on the back.`,
		price: [ 55, 60 ],
		image: [ { src: process.env.PUBLIC_URL + '/native-bee-green-long-sleeve-tee.jpg', caption: ''} ],
		params: [ {
			type: 'size',
			option: 'T-Shirt Size',
			values: [
				'XXL',
				'XL',
				'L',
				'M',
				'S',
			]
		}],
		weight: { base: 0.16, S: 0, M: 0.03, L: 0.05	, XL: 0.08, XXL: 0.1},
		tax: true,
		public: true,
	},
	{
		id: 'anunakiu-murri-time-black-tee',
		name: 'Anunakiu Murri Time - Black Tee',
		description: `Celebrate the rich history of Indigenous music with the Original 4ZZZ Murri Time T-shirt designed by Anunakiu in 1983! This T-shirt commemorates the formation of 4ZZZ\'s Murri Hour Radio in 1983 and pays homage to Indigenous musicians of the time, whose songs were messages of struggles and triumphs of the Brisbane Blacks. This T-shirt represents an important historical milestone in the Brisbane Blacks community, from 1982 to the 1990s, so don\'t pass on this opportunity to honor history!
		
		All proceeds go towards training and development of First Nations folk at 4ZZZ.`,
		price: [ 35, 40 ],
		image: [ { src: process.env.PUBLIC_URL + '/anunakiu-murri-time-tee-black.jpg', caption: ''} ],
		params: [ {
			type: 'size',
			option: 'T-Shirt Size',
			values: [
				'XXL',
				'XL',
				'L',
				'M',
				'S',
			]
		}],
		weight: { base: 0.16, S: 0, M: 0.03, L: 0.05	, XL: 0.08, XXL: 0.1},
		tax: true,
		public: true,
	},
	{
		id: 'anunakiu-murri-time-white-tee',
		name: 'Anunakiu Murri Time - White Tee',
		description: `Celebrate the rich history of Indigenous music with the Original 4ZZZ Murri Time T-shirt designed by Anunakiu in 1983! This T-shirt commemorates the formation of 4ZZZ\'s Murri Hour Radio in 1983 and pays homage to Indigenous musicians of the time, whose songs were messages of struggles and triumphs of the Brisbane Blacks. This T-shirt represents an important historical milestone in the Brisbane Blacks community, from 1982 to the 1990s, so don\'t pass on this opportunity to honor history!
		
		All proceeds go towards training and development of First Nations folk at 4ZZZ.`,
		price: [ 35, 40 ],
		image: [ { src: process.env.PUBLIC_URL + '/anunakiu-murri-time-tee-white.jpg', caption: ''} ],
		params: [ {
			type: 'size',
			option: 'T-Shirt Size',
			values: [
				'XXL',
				'XL',
				'L',
				'M',
				'S',
			]
		}],
		weight: { base: 0.16, S: 0, M: 0.03, L: 0.05	, XL: 0.08, XXL: 0.1},
		tax: true,
		public: true,
	},
	{
		id: 'state-of-emergence-tee-fitted',
		name: 'State Of Emergence Tee - Fitted Cut',
		description: 'Rehashing a classic design that originally appeared as 4ZZZ\'s 1986 compilation record State Of Emergence. Designed by Cora Lansdell and lovingly printed by Red Octopus on a black AS colour tee in fitted and classic cut. Stephen wears his loose cut as a DIY crop and Charlotte wears the fitted cut.',
		price: [ 35, 40 ],
		image: [ { src: process.env.PUBLIC_URL + '/state-of-emergence-tee-outside.jpg', caption: ''} ],
		params: [ {
			type: 'size',
			option: 'T-Shirt Size',
			values: [
				'XL',
				'L',
				'M',
				'S',
				'XS'
			]
		}],
		weight: { base: 0.16, S: 0, M: 0.03, L: 0.05	, XL: 0.08, XXL: 0.1},
		tax: true,
		public: true,
	},
	{
		id: 'state-of-emergence-tee-loose',
		name: 'State Of Emergence Tee - Loose Cut',
		description: 'Rehashing a classic design that originally appeared as 4ZZZ\'s 1986 compilation record State Of Emergence. Designed by Cora Lansdell and lovingly printed by Red Octopus on a black AS colour tee in fitted and classic cut. Stephen wears his loose cut as a DIY crop and Charlotte wears the fitted cut.',
		price: [ 30, 35 ],
		image: [ { src: process.env.PUBLIC_URL + '/state-of-emergence-tee.jpg', caption: ''} ],
		params: [ {
			type: 'size',
			option: 'T-Shirt Size',
			values: [
				'XXL',
				'XL',
				'L',
				'M',
				'S'
			]
		}],
		weight: { base: 0.16, S: 0, M: 0.03, L: 0.05	, XL: 0.08, XXL: 0.1},
		tax: true,
		public: true,
	},
	{
		id: 'new-zealand-show-anniversary-tee',
		name: 'New Zealand Show Anniversary Tee',
		description: 'Celebrate 40 years of Kiwi attitude on 4ZZZs airwaves with this special edition New Zealand Show Tee featuring the art of one of their own Bernie Ham on a natural AS colour tee.',
		price: [ 35, 40 ],
		image: [ { src: process.env.PUBLIC_URL + '/new-zealand-show-anniversary-tee.jpg', caption: ''} ],
		params: [ {
			type: 'size',
			option: 'T-Shirt Size',
			values: [
				'XXL',
				'XL',
				'L',
				'M',
				'S'
			]
		}],
		weight: { base: 0.16, S: 0, M: 0.03, L: 0.05	, XL: 0.08, XXL: 0.1},
		tax: true,
		public: true,
	},
	{
		id: 'hot-100-2023-tee',
		name: 'Hot 100 2023 Tee',
		description: 'Hot 100 limited edition tee featuring artwork by Emetic Art',
		price: [ 35, 40 ],
		image: [ { src: process.env.PUBLIC_URL + '/hot-100-2023-tee.jpg', caption: ''} ],
		params: [ {
			type: 'size',
			option: 'T-Shirt Size',
			values: [
				'XXL',
				'XL',
				'L',
				'M',
				'S'
			]
		}],
		weight: { base: 0.16, S: 0, M: 0.03, L: 0.05	, XL: 0.08, XXL: 0.1},
		tax: true,
		public: true,
	},
	{
		id: 'classic-michael-barnett-tee',
		name: 'Classic Michael Barnett Tee',
		description: 'Bringing back a classic 1980\'s 4ZZZ favourite designed by Brisbane artist Michael Barnett. Lovingly printed by Red Octopus locally on a white, loose fit, AS colour tee.',
		price: [ 35, 40 ],
		image: [ { src: process.env.PUBLIC_URL + '/michael-barnett-tee-white.jpg', caption: ''} ],
		params: [ {
			type: 'size',
			option: 'T-Shirt Size',
			values: [
				'XXL',
				'XL',
				'L',
				'M',
				'S'
			]
		}],
		weight: { base: 0.16, S: 0, M: 0.03, L: 0.05	, XL: 0.08, XXL: 0.1},
		tax: true,
		public: true,
	},
	{
		id: 'phoebe-paradise-tee-fitted',
		name: 'Phoebe Paradise Tee - Fitted Cut',
		description: 'Back by popular demand, Phoebe Paradise\'s oozing ode to Brisbane\'s notorious humidity. Guaranteed to keep you looking cool, while the temperature rises. Lovingly printed by Red Octopus locally on a black, fitted AS colour tee.',
		price: [ 35, 40  ],
		image: [ { src: process.env.PUBLIC_URL + '/phoebe-paradise-tee-black-outside.jpg', caption: ''} ],
		params: [ {
			type: 'size',
			option: 'T-Shirt Size',
			values: [
				'XL',
				'L',
				'M',
				'S',
				'XS'
			]
		}],
		weight: { base: 0.16, S: 0, M: 0.03, L: 0.05	, XL: 0.08, XXL: 0.1},
		tax: true,
		public: true,
	},
	{
		id: 'phoebe-paradise-tee-loose',
		name: 'Phoebe Paradise Tee - Loose Cut',
		description: 'Back by popular demand, Phoebe Paradise\'s oozing ode to Brisbane\'s notorious humidity. Guaranteed to keep you looking cool, while the temperature rises. Lovingly printed by Red Octopus locally on a black, loose fit, AS colour tee.',
		price: [ 35, 40 ],
		image: [ { src: process.env.PUBLIC_URL + '/phoebe-paradise-tee-black.jpg', caption: ''} ],
		params: [ {
			type: 'size',
			option: 'T-Shirt Size',
			values: [
				'XXL',
				'XL',
				'L',
				'M',
				'S'
			]
		}],
		weight: { base: 0.16, S: 0, M: 0.03, L: 0.05	, XL: 0.08, XXL: 0.1},
		tax: true,
		public: true,
	},
	{
		id: 'george-levi-banana-socks',
		name: 'George Levi Banana Socks',
		image: [ { src: process.env.PUBLIC_URL + '/george-levi-banana-socks.jpg', caption: ''} ],
		description: 'George Levi Banana Socks.',
		price: [20, 25 ],
		params: [ {
			type: 'size',
			option: 	'Size',
			values: [
				'5-7',
				'7-12',
			]
		}],
		weight: { base: 0.14, '5-7': 0, '7-12': 0 },
		tax: true,
		public: true,
	},

	{
		id: 'george-levi-sports-socks',
		name: 'George Levi Sports Socks',
		image: [ { src: process.env.PUBLIC_URL + '/george-levi-sports-socks.jpg', caption: ''} ],
		description: 'George Levi Sports Socks',
		price: [20, 25 ],
		params: [ {
			type: 'size',
			option: 	'Size',
			values: [
				'5-7',
				'7-12',
			]
		}],
		weight: { base: 0.14, '5-7': 0, '7-12': 0 },
		tax: true,
		public: true,
	},
	{
		id: 'classic-banana-tea-towel',
		name: 'Classic Banana Tea Towel',
		image: [ { src: process.env.PUBLIC_URL + '/classic-banana-tea-towel.jpg', caption: ''} ],
		description: 'Linen tea towel with the original 70s banana logo. 70 x 50cm',
		price: [ 15, 20 ],
		weight: { base: 0.14},
		tax: true,
		public: true,
	},
	{
		id: 'classic-poster-bandana',
		name: 'Classic Poster Bandana',
		description: "Our 45th Anniversary print featuring iconic 4ZZZ posters & artwork. Screen printed on soft white cotton blend by Red Octopus. Frame it, wear it, buy one for your dog. 52cm x 52cm.",
		price: [ 15, 20 ],
		image: [ { src: process.env.PUBLIC_URL + '/classic-poster-bandana.jpg', caption: ''} ],
		weight: { base: 0 },
		tax: true,
		public: true,
	},

	// {
	// 	id: '45th-anniversary-tee',
	// 	name: '45th Anniversary Tee',
	// 	description: '4ZZZ 45 years commemorative tee, "independent and live since 1975", pink text on black.',
	// 	price: [ 40, 45 ],
	// 	image: [ { src: process.env.PUBLIC_URL + '/45th-anniversary-tee.jpg', caption: ''} ],
	// 	params: [ {
	// 		type: 'size',
	// 		option: 'T-Shirt Size',
	// 		values: [
	// 			'XXL',
	// 			'XL',
	// 			'L',
	// 			'M',
	// 			'S'
	// 		]
	// 	}],
	// 	weight: { base: 0.16, S: 0, M: 0.03, L: 0.05	, XL: 0.08, XXL: 0.1},
	// 	tax: true,
	// 	public: true,
	// },

	// {
	// 	id: 'curly-logo-tee-loose',
	// 	name: '4ZZZ Curly Logo Classic Ringer Tee - Loose Cut',
	// 	description: '4ZZZ curly logo, classic ringer tee, loose cut. White tee, black rings.',
	// 	price: [ 40, 45 ],
	// 	image: [ { src: process.env.PUBLIC_URL + '/curly-logo-tee-loose.jpg', caption: ''} ],
	// 	params: [ {
	// 		type: 'size',
	// 		option: 'T-Shirt Size',
	// 		values: [
	// 			'XXL',
	// 			'XL',
	// 			'L',
	// 			'M',
	// 			'S'
	// 		]
	// 	}],
	// 	weight: { base: 0.16, S: 0, M: 0.03, L: 0.05	, XL: 0.08, XXL: 0.1},
	// 	tax: true,
	// 	public: true,
	// },
	// {
	// 	id: 'curly-logo-tee-fitted',
	// 	name: '4ZZZ Curly Logo Classic Ringer Tee - Fitted Cut',
	// 	description: '4ZZZ curly logo, classic ringer tee, fitted cut. White tee, black rings.',
	// 	price: [ 40, 45 ],
	// 	image: [ { src: process.env.PUBLIC_URL + '/curly-logo-tee-fitted.jpg', caption: ''} ],
	// 	params: [ {
	// 		type: 'size',
	// 		option: 'T-Shirt Size',
	// 		values: [
	// 			'XL',
	// 			'L',
	// 			'M',
	// 			'S',
	// 			'XS',
	// 		]
	// 	}],
	// 	weight: { base: 0.16, S: 0, M: 0.03, L: 0.05	, XL: 0.08, XXL: 0.1},
	// 	tax: true,
	// 	public: true,
	// },
	// {
	// 	id: 'curly-logo-bucket-hat',
	// 	name: '4ZZZ Curly Logo Bucket Hat',
	// 	description: '4ZZZ curly logo bucket hat. Black with pink embroidered logo, commemorating 45 years.',
	// 	price: [ 30, 35 ],
	// 	image: [ { src: process.env.PUBLIC_URL + '/curly-logo-bucket-hat.jpg', caption: ''} ],
	// 	weight: { base: 0.16, S: 0, M: 0.03, L: 0.05	, XL: 0.08, XXL: 0.1},
	// 	tax: true,
	// 	public: true,
	// },
	{
		id: 'banana-logo-patch',
		name: 'Banana Logo Woven Patch',
		description: 'Classic 4ZZZ banana logo woven patch. 105 x 90mm',
		price: [ 15, 20 ],
		image: [ { src: process.env.PUBLIC_URL + '/banana-logo-patch.jpg', caption: ''} ],
		weight: { base: 0},
		tax: true,
		public: true,
	},
	{
		id: 'curly-logo-patch',
		name: 'Curly Logo Embroidered Patch',
		description: 'Classic 4ZZZ curly logo embroidered patch. 150 x 70mm',
		price: [ 15, 20 ],
		image: [ { src: process.env.PUBLIC_URL + '/curly-logo-patch.jpg', caption: ''} ],
		weight: { base: 0},
		tax: true,
		public: true,
	},
	{
		id: '4zzz-grid-back-patch',
		name: '4ZZZ Grid Back Patch',
		description: 'Screen printed classic 4ZZZ design back patch. 280 x 320mm',
		price: [ 20, 25 ],
		image: [ { src: process.env.PUBLIC_URL + '/4zzz-grid-back-patch.jpg', caption: ''} ],
		weight: { base: 0.16, S: 0, M: 0.03, L: 0.05	, XL: 0.08, XXL: 0.1},
		tax: true,
		public: true,
	},
	{
		id: 'banana-logo-magnet',
		name: 'Banana Logo Magnet',
		description: "Classic 4ZZZ banana logo on a fridge magnet. 'No other radio like this.' 90mm x 50mm",
		price: [ 5, 10 ],
		image: [ { src: process.env.PUBLIC_URL + '/banana-logo-magnet.jpg', caption: ''} ],
		weight: { base: 0 },
		tax: true,
		public: true,
	},
	{
		id: 'black-community-radio-tee',
		name: 'Black 4ZZZ Brisbane Community Radio Tee',
		description: 'Black TEE, design by Breah Robilliard',
		price: [ 25, 30 ],
		image: [ { src: process.env.PUBLIC_URL + '/community-t-black.jpg', caption: ''} ],
		params: [ {
			type: 'size',
			option: 'T-Shirt Size',
			values: [
				'XXL',
				'XL',
				'L',
				'M',
				'S'
			]
		}],
		weight: { base: 0.16, S: 0, M: 0.03, L: 0.05	, XL: 0.08, XXL: 0.1},
		tax: true,
		public: true,
	},

	// {
	// 	id: 'gold-community-radio-tee',
	// 	name: 'Gold 4ZZZ Brisbane Community Radio Tee',
	// 	description: 'Gold Brisbane Community Radio Tee designed by Breah Robilliard',
	// 	image: [ { src: process.env.PUBLIC_URL + '/community-gold-tee.jpg', caption: ''} ],
	// 	price: [ 25, 30 ],
	// 	params: [ {
	// 		type: 'size',
	// 		option: 'T-Shirt Size',
	// 		values: [
	// 			'XXL',
	// 			'XL',
	// 			'L',
	// 			'M',
	// 			'S'
	// 		]
	// 	}],
	// 	weight: { base: 0.16, S: 0, M: 0.03, L: 0.05, XL: 0.08, XXL: 0.1},
	// 	tax: true,
	// 	public: true,
	// },

	// {
	// 	id: 'burgundy-banana-tee',
	// 	name: 'Burgundy Banana Tee',
	// 	description: 'Burgundy Vintage Banana Tee',
	// 	image: [ { src: process.env.PUBLIC_URL + '/burgundy-banana-tee.jpg', caption: ''} ],
	// 	price: [ 25, 30 ],
	// 	params: [ {
	// 		type: 'size',
	// 		option: 'T-Shirt Size',
	// 		values: [
	// 			'XXL',
	// 			'XL',
	// 			'L',
	// 			'M',
	// 			'S'
	// 		]
	// 	}],
	// 	weight: { base: 0.16, S: 0, M: 0.03, L: 0.05, XL: 0.08, XXL: 0.1},
	// 	tax: true,
	// 	public: true,
	// },

	{
		id: 'yellow-banana-tee',
		name: 'Yellow Banana Tee',
		description: 'Yellow Vintage Banana Tee',
		image: [ { src: process.env.PUBLIC_URL + '/yellow-banana-tee.jpg', caption: ''} ],
		price: [ 25, 30 ],
		params: [ {
			type: 'size',
			option: 'T-Shirt Size',
			values: [
				'XXL',
				'XL',
				'L',
				'M',
				'S'
			]
		}],
		weight: { base: 0.16, S: 0, M: 0.03, L: 0.05, XL: 0.08, XXL: 0.1},
		tax: true,
		public: true,
	},

	{
		id: 'red-banana-tee',
		name: 'Red Banana Tee',
		description: 'Red Vintage Banana Tee',
		image: [ { src: process.env.PUBLIC_URL + '/red-banana-tee.jpg', caption: ''} ],
		price: [ 25, 30 ],
		params: [ {
			type: 'size',
			option: 'T-Shirt Size',
			values: [
				'XXL',
				'XL',
				'L',
				'M',
				'S'
			]
		}],
		weight: { base: 0.16, S: 0, M: 0.03, L: 0.05, XL: 0.08, XXL: 0.1},
		tax: true,
		public: true,
	},


	{
		id: 'black-banana-tee',
		name: 'Black Banana Tee',
		description: 'Black Vintage Banana Tee',
		image: [ { src: process.env.PUBLIC_URL + '/black-banana-tee.jpg', caption: ''} ],
		price: [ 25, 30 ],
		params: [ {
			type: 'size',
			option: 'T-Shirt Size',
			values: [
				'XXL',
				'XL',
				'L',
				'M',
				'S'
			]
		}],
		weight: { base: 0.16, S: 0, M: 0.03, L: 0.05, XL: 0.08, XXL: 0.1},
		tax: true,
		public: true,
	},

	{
		id: 'blue-white-banana-tee',
		name: 'Blue & White Banana Tee',
		description: 'Blue & White White Vintage Banana Tee, Black Logo',
		image: [ { src: process.env.PUBLIC_URL + '/blue-white-banana-tee.jpg', caption: ''} ],
		price: [ 25, 30 ],
		params: [ {
			type: 'size',
			option: 'T-Shirt Size',
			values: [
				'XXL',
				'XL',
				'L',
				'M',
				'S'
			]
		}],
		weight: { base: 0.16, S: 0, M: 0.03, L: 0.05, XL: 0.08, XXL: 0.1},
		tax: true,
		public: true,
	},

	{
		id: 'white-banana-tee',
		name: 'White Banana Tee',
		description: 'White Vintage Banana Tee, Black Logo',
		image: [ { src: process.env.PUBLIC_URL + '/white-banana-tee.jpg', caption: ''} ],
		price: [ 25, 30 ],
		params: [ {
			type: 'size',
			option: 'T-Shirt Size',
			values: [
				'XXL',
				'XL',
				'L',
				'M',
				'S'
			]
		}],
		weight: { base: 0.16, S: 0, M: 0.03, L: 0.05, XL: 0.08, XXL: 0.1},
		tax: true,
		public: true,
	},

	{
		id: 'pink-banana-tee',
		name: 'Pink Banana Tee',
		description: 'Pink Vintage Banana Tee, Purple Logo',
		image: [ { src: process.env.PUBLIC_URL + '/pink-banana-tee.jpg', caption: ''} ],
		price: [ 25, 30 ],
		params: [ {
			type: 'size',
			option: 'T-Shirt Size',
			values: [
				'XXL',
				'XL',
				'L',
				'M',
				'S'
			]
		}],
		weight: { base: 0.16, S: 0, M: 0.03, L: 0.05, XL: 0.08, XXL: 0.1},
		tax: true,
		public: true,
	},

	{
		id: 'blue-banana-tee',
		name: 'Blue Banana Tee',
		description: 'Blue Vintage Banana Tee, Orange Logo',
		image: [ { src: process.env.PUBLIC_URL + '/blue-banana-tee.jpg', caption: ''} ],
		price: [ 25, 30 ],
		params: [ {
			type: 'size',
			option: 'T-Shirt Size',
			values: [
				'XXL',
				'XL',
				'L',
				'M',
				'S'
			]
		}],
		weight: { base: 0.16, S: 0, M: 0.03, L: 0.05, XL: 0.08, XXL: 0.1},
		tax: true,
		public: true,
	},


	// {
	// 	id: 'teal-banana-tee',
	// 	name: 'Teal Banana Tee',
	// 	description: 'Galapagos Blue Vintage Banana Tee',
	// 	image: [ { src: process.env.PUBLIC_URL + '/teal-banana-tee.jpg', caption: ''} ],
	// 	price: [ 20, 25 ],
	// 	params: [ {
	// 		type: 'size',
	// 		option: 'T-Shirt Size',
	// 		values: [
	// 			'XXL',
	// 			'XL',
	// 			'L',
	// 			'M',
	// 			'S'
	// 		]
	// 	}],
	// 	weight: { base: 0.16, S: 0, M: 0.03, L: 0.05, XL: 0.08, XXL: 0.1},
	// 	tax: true,
	// 	public: true,
	// },
	// {
	// 	id: 'radio-carnival-tee',
	// 	name: '4ZZZ Radio Carnival Tee',
	// 	price: [ 25, 30 ],
	// 	image: [ { src: process.env.PUBLIC_URL + '/radio-carnival-tee.jpg', caption: ''} ],
	// 	description: 'Radio Carnival Tee',
	// 	params: [ {
	// 		type: 'size',
	// 		option: 'T-Shirt Size',
	// 		values: [
	// 			'XXL',
	// 			'XL',
	// 			'L',
	// 			'M',
	// 			'S'
	// 		]
	// 	}],
	// 	weight: { base: 0.16, S: 0, M: 0.03, L: 0.05, XL: 0.08, XXL: 0.1},
	// 	tax: true,
	// 	public: true,
	// },

	// {
	// 	id: 'radio-carnival-tote',
	// 	name: 'Radio Carnival Tote',
	// 	price: [ 15, 20 ],
	// 	image: [ { src: process.env.PUBLIC_URL + '/radio-carnival-tote.jpg', caption: ''} ],
	// 	description: 'Black Radio Carnival Tote, heavy duty fabric.',
	// 	weight: { base: 0.14 },
	// 	tax: true,
	// 	public: false,
	// },
	// {
	// 	id: 'black-logo-tee',
	// 	name: 'Black 4ZZZ Logo tee (Medium Only)',
	// 	price: [ 20, 25 ],
	// 	image: [ { src: process.env.PUBLIC_URL + '/circle-black.jpg', caption: ''} ],
	// 	params: [ {
	// 		type: 'size',
	// 		option: 'T-Shirt Size',
	// 		values: [
	// 			'M',
	// 		]
	// 	}],
	// 	weight: { base: 0.16, S: 0, M: 0.03, L: 0.05, XL: 0.08, XXL: 0.1},
	// 	tax: true,
	// 	public: false,
	// },
	// {
	// 	id: 'keep-brisbane-weird-tee',
	// 	name: 'Black 2018 Keep Brisbane Weird Tee',
	// 	price: [ 10, 15 ],
	// 	image: [ { src: process.env.PUBLIC_URL + '/merch_radiothon_tee_2018.JPG', caption: ''} ],
	// 	description: 'Last chance to buy 2018 Radiothon Tee only Medium available. "Keep Brisbane Weird"',
	// 	params: [ {
	// 		type: 'size',
	// 		option: 'T-Shirt Size',
	// 		values: [
	// 			'M',
	// 		]
	// 	}],
	// 	weight: { base: 0.16, S: 0, M: 0.03, L: 0.05, XL: 0.08, XXL: 0.1},
	// 	tax: true,
	// 	public: true,
	// },
	// {
	// 	id: 'keep-brisbane-weird-tote',
	// 	name: 'Black 2018 Keep Brisbane Weird Tote',
	// 	image: [ { src: process.env.PUBLIC_URL + '/merch_radiothon_tote_2018.JPG', caption: ''} ],
	// 	description: 'Black Cotton Canvas Long Handle Tote with Keep Brisbane Weird Design and roomy enough for your fave LP.',
	// 	price: [ 15, 20 ],
	// 	weight: { base: 0.14},
	// 	tax: true,
	// 	public: true,
	// },
	// {
	// 	id: 'banana-logo-tote',
	// 	name: 'Banana Logo Tote',
	// 	image: [ { src: process.env.PUBLIC_URL + '/banana-logo-tote.jpg', caption: ''} ],
	// 	description: 'Black Cotton Tote with classic banana logo.',
	// 	price: [ 10, 15 ],
	// 	weight: { base: 0.14},
	// 	tax: true,
	// 	public: true,
	// },

	{
		id: '4zzz-grid-purple-tote',
		name: '4ZZZ Grid Tote (Purple & Blue)',
		image: [ { src: process.env.PUBLIC_URL + '/4zzz-grid-purple-tote.jpg', caption: ''} ],
		description: 'Triple Zed Grid heavy AS Carrie Tote in black, blue and purple hues.',
		price: [ 20, 25 ],
		weight: { base: 0.16},
		tax: true,
		public: true,
	},

	{
		id: 'banana-drink-coasters',
		name: 'Banana Drink Coasters',
		image: [ { src: process.env.PUBLIC_URL + '/banana-drink-coasters.jpg', caption: ''} ],
		description: 'Pack of four cork coasters with Matt Mawsons classic banana logo design.',
		price: [ 10, 20 ],
		weight: { base: 0.14},
		tax: true,
		public: true,
	},

	{
		id: 'banana-socks',
		name: 'Banana Socks',
		image: [ { src: process.env.PUBLIC_URL + '/banana-socks.jpg', caption: ''} ],
		description: '4ZZZ Banana Socks for the sneaker loving Zed supporter. One size fits all.',
		price: [10, 15 ],
		params: [ {
			type: 'size',
			option: 	'Size',
			values: [
				'2-8',
				'7-11',
			]
		}],
		weight: { base: 0.14, '2-8': 0, '7-11': 0 },
		tax: true,
		public: true,
	},

	
	// {
	// 	id: 'stubby-cooler',
	// 	name: 'Black Pineapple Stubby Cooler',
	// 	image: [ { src: process.env.PUBLIC_URL + '/stubby-cooler.jpg', caption: ''} ],
	// 	detail: 'Pineapple Stubby Cooler  - BLACK ONLY',
	// 	description: 'Keep your cans cold with our 4ZZZ stubby coolers.',
	// 	price: [ 8, 10 ],
	// 	params: [ {
	// 		type: 'colour',
	// 		option: 'Colour',
	// 		values: [
	// 			'Black',
	// 		]
	// 	}],
	// 	weight: { base: 0.14},
	// 	tax: true,
	// 	public: true,
	// },
	{
		id: 'grid-stubby-cooler',
		name: 'Classic Zed Grid Stubby Cooler',
		image: [ { src: process.env.PUBLIC_URL + '/grid-stubby-cooler.jpg', caption: ''} ],
		detail: 'Classic Zed Grid Stubby Cooler',
		description: 'Keep your cans cold with our 4ZZZ stubby coolers.',
		price: [ 8, 10 ],
		weight: { base: 0.14},
		tax: true,
		public: true,
	},

	{
		id: 'yellow-4zzz-heart-pin',
		name: 'Yellow 4ZZZ Heart Pin',
		image: [ { src: process.env.PUBLIC_URL + '/yellow-4zzz-heart-pin.jpg', caption: ''} ],
		detail: '',
		description: 'Yellow 4ZZZ text on black pin, designed by George Levi.',
		weight: { base: 0},
		price: [ 7, 12 ],
		tax: true,
		public: true,
	},


	{
		id: 'banana-logo-pin',
		name: 'Banana Logo Pin',
		image: [ { src: process.env.PUBLIC_URL + '/banana-logo-pin.jpg', caption: ''} ],
		detail: '',
		description: '4ZZZs classic banana logo pin.',
		weight: { base: 0},
		price: [ 5, 10 ],
		tax: true,
		public: true,
	},

	// {
	// 	id: 'netherwold-pin',
	// 	name: 'Netherworld Microphone Pin',
	// 	image: [ { src: process.env.PUBLIC_URL + '/netherworld-pin.jpg', caption: ''} ],
	// 	detail: '',
	// 	description: 'Netherworld designed colab, skeletal microphone pin.',
	// 	price: [ 5, 10 ],
	// 	tax: true,
	// 	public: true,
	// },
	{
		id: 'keyring-bottle-opener',
		name: '4ZZZ Keyring Bottle Opener',
		image: [ { src: process.env.PUBLIC_URL + '/colour-keyrings.jpg', caption: ''} ],
		detail: 'The 4ZZZ Guitar Bottle Opener Keyring',
		description: 'This handy keychain will hold your keys and also take your bottle caps off!',
		price: [ 5, 7 ],
		params: [ {
			type: 'colour',
			option: 'Colour',
			values: [
				'Red',
				'Green',
				'Blue',
			]
		}],
		tax: true,
		public: true,
	},
	{
		id: 'whats-your-pick',
		name: 'What\'s your Pick',
		image: [ { src: process.env.PUBLIC_URL + '/merch_picks.JPG', caption: ''} ],
		description: '4ZZZ Celluloid Guitar Picks (0.71mm) Pack of 3 or 5 in variety of colours: \'Pearl White\', \'Red\', \'Blue\', \'Confetti\', \'Green\', \'Tortoiseshell\', \'Rainbow\'',
		price: [5, 7],
		params: [ {
			type: 'pack',
			option: 'Pack',
			values: [
				'Pack of 3',
				'Pack of 5',
			]
		}],
		weight: { base: 0.04 },
		pack: { 'Pack of 3': [5, 7], 'Pack of 5': [8, 10] },
		tax: true,
		public: true,
	},
	{
		id: 'guitar-pick-earrings',
		name: 'Guitar Pick Earrings',
		image: [ { src: process.env.PUBLIC_URL + '/earrings.jpg', caption: ''} ],
		description: '4ZZZ Guitar Pick Earrings silver hoop in a variety of colours:',
		price: [ 5, 7 ],
		params: [{
			type: 'colour',
			option: 'Colour',
			values: [
			 'Pearl White',
			 'Blue',
			 'Confetti',
			 'Green',
			 'Rainbow',
			 'Red',
			 'Tortoiseshell',
		 	],
		}],
		weight: { base: 0.04 },
		tax: true,
		public: true,
	},

	{
		id: 'generation-zed',
		name: '4zzz Generation Zed Book',
		image: [ { src: process.env.PUBLIC_URL + '/generation-zed.jpg', caption: ''} ],
		description: 'From our humble beginnings at UQ over 40 years ago find out what makes zed ...  zed.',
		price: [15, 20],
		weight: { base: 0.287 },
		tax: true,
		public: true,
	},

	// {
	// 	id: 'april-atonement-tote',
	// 	name: 'April Atonement Tote',
	// 	image: [ { src: process.env.PUBLIC_URL + '/april-atonement-tote.jpg', caption: ''} ],
	// 	description: 'Black Cotton Canvas Long Handle Tote with April Atonement Design by Breah Robilliard.',
	// 	price: [ 10, 15 ],
	// 	weight: { base: 0.14},
	// 	tax: true,
	// 	public: false,
	// },

	// {
	// 	id: 'coffee-mug',
	// 	name: 'Coffee Mug',
	// 	image: [ { src: process.env.PUBLIC_URL + '/coffee-mug.jpg', caption: ''} ],
	// 	description: 'Gold Brisbane Community Radio Coffee Mug designed by Breah Robilliard.',
	// 	price: [ 8, 10 ],
	// 	weight: { base: 0.14},
	// 	tax: true,
	// 	public: false,
	// },
	// {
	// 	id: '4zzz-and-zed-digital-tee',
	// 	name: '4ZZZ And Zed Digital Tee',
	// 	image: [ { src: process.env.PUBLIC_URL + '/4zzz-and-zed-digital-tee.jpg', caption: ''} ],
	// 	description: '4ZZZ and Zed Digital tee, white cotton tee with illustration on the reverse side.',
	// 	price: [ 20, 25 ],
	// 	params: [ {
	// 		type: 'size',
	// 		option: 'T-Shirt Size',
	// 		values: [
	// 			'XXL',
	// 		]
	// 	}],
	// 	weight: { base: 0.16, S: 0, M: 0.03, L: 0.05, XL: 0.08, XXL: 0.1},
	// 	tax: true,
	// 	public: true,
	// },

	// {
	// 	id: 'we-built-this-station-tee',
	// 	name: 'We Built This Station Tee',
	// 	image: [ { src: process.env.PUBLIC_URL + '/we-built-this-station-tee.jpg', caption: ''} ],
	// 	description: 'We Built This Station white cotton illustrated tee.',
	// 	price: [ 10, 15 ],
	// 	params: [ {
	// 		type: 'size',
	// 		option: 'T-Shirt Size',
	// 		values: [
	// 			'M', 'XL', 'XXL',
	// 		]
	// 	}],
	// 	weight: { base: 0.16, S: 0, M: 0.03, L: 0.05, XL: 0.08, XXL: 0.1},
	// 	tax: true,
	// 	public: true,
	// },

	{
		id: 'we-built-this-station-tote',
		name: 'We Built This Station Tote',
		image: [ { src: process.env.PUBLIC_URL + '/we-built-this-station-tote.jpg', caption: ''} ],
		description: 'Black Cotton Canvas Long Handle Tote with April Atonement Design by Breah Robilliard.',
		price: [ 10, 15 ],
		weight: { base: 0.16},
		tax: true,
		public: true,
	},
	{
		id: 'locked-in-krew-sticker',
		name: 'Locked In Krew Sticker', image: [ { src: process.env.PUBLIC_URL + '/locked-in-krew-sticker.jpg', caption: ''} ], description: 'Locked In Krew Sticker',
		price: [ 5, 7 ],
		weight: { base: 0},
		tax: true,
		public: true,
	},
	{
		id: 'nineties-banana-sticker',
		name: 'Nineties Banana Sticker',
		image: [ { src: process.env.PUBLIC_URL + '/nineties-banana-sticker.jpg', caption: ''} ],
		description: 'Nineties banana logo sticker by Will F.X.',
		price: [ 5, 7 ],
		weight: { base: 0},
		tax: true,
		public: true,
	},

	{
		id: 'eighties-triplez-sticker',
		name: 'Eighties TripleZ Sticker',
		image: [ { src: process.env.PUBLIC_URL + '/eighties-triplez-sticker.jpg', caption: ''} ],
		description: 'Classic TripleZ from 1987 Radiothon',
		price: [ 5, 7 ],
		weight: { base: 0},
		tax: true,
		public: true,
	},

	{
		id: 'holographic-4zzz-sticker',
		name: 'Holographic 4ZZZ Sticker',
		image: [ { src: process.env.PUBLIC_URL + '/holographic-4zzz-sticker.jpg', caption: ''} ],
		description: '4ZZZ exclamation bubble holographic sticker',
		price: [ 5, 7 ],
		weight: { base: 0},
		tax: true,
		public: true,
	},

	{
		id: 'matt-mawson-banana-sticker',
		name: 'Matt Mawson Banana Sticker',
		image: [ { src: process.env.PUBLIC_URL + '/matt-mawson-banana-sticker.jpg', caption: ''} ],
		description: 'Matt Mawson classic banana logo sticker',
		price: [ 5, 7 ],
		weight: { base: 0},
		tax: true,
		public: true,
	},

	{
		id: '4zzz-logo-sticker',
		name: '4ZZZ Logo Sticker',
		image: [ { src: process.env.PUBLIC_URL + '/4zzz-logo-sticker.jpg', caption: ''} ],
		description: 'The no nonsense 4ZZZ logo, as a sticker.',
		price: [ 5, 7 ],
		weight: { base: 0},
		tax: true,
		public: true,
	},

	{
		id: 'monster-bumper-sticker',
		name: 'Monster Bumper Sticker',
		image: [ { src: process.env.PUBLIC_URL + '/monster-bumper-sticker.jpg', caption: ''} ],
		description: 'Monster Bumper Sticker based on design by Clare Neal',
		price: [ 5, 7 ],
		weight: { base: 0},
		tax: true,
		public: true,
	},

	{
		id: 'back-yard-bumper-sticker',
		name: 'Back Yard Bumper Sticker',
		image: [ { src: process.env.PUBLIC_URL + '/back-yard-bumper-sticker.jpg', caption: ''} ],
		description: 'Back Yard Bumper Sticker based on design by Josh Murphy',
		price: [ 5, 7 ],
		weight: { base: 0},
		tax: true,
		public: true,
	},

	{
		id: 'brisbane-community-radio-sticker',
		name: 'Brisbane Community Radio Sticker',
		image: [ { src: process.env.PUBLIC_URL + '/brisbane-community-radio-sticker.png', caption: ''} ],
		description: 'Black Brisbane Community Radio sticker, designed by Breah Robilliard.',
		price: [ 3, 5 ],
		weight: { base: 0},
		tax: true,
		public: true,
	},

	{
		id: '4zzz-grid-sticker',
		name: '4ZZZ Grid Sticker',
		image: [ { src: process.env.PUBLIC_URL + '/4zzz-grid-sticker.jpg', caption: ''} ],
		description: '4ZZZ grid square sticker.',
		price: [ 3, 5 ],
		weight: { base: 0},
		tax: true,
		public: true,
	},

	{
		id: '4zzz-building-sticker',
		name: '4ZZZ Building Sticker',
		image: [ { src: process.env.PUBLIC_URL + '/4zzz-building-sticker.jpg', caption: ''} ],
		description: '4ZZZ building square sticker.',
		price: [ 3, 5 ],
		weight: { base: 0},
		tax: true,
		public: true,
	},

	// {
	// 	id: 'carnival-sticker',
	// 	name: 'Carnival Sticker',
	// 	image: [ { src: process.env.PUBLIC_URL + '/carnival-sticker.png', caption: ''} ],
	// 	description: 'Carnival Sticker',
	// 	price: [ 1, 2 ],
	// 	weight: { base: 0},
	// 	tax: true,
	// 	public: false,
	// },

	{
		id: 'misc',
		name: 'Miscellaneous',
		image: [ { src: process.env.PUBLIC_URL + '/4zzz.svg', caption: ''} ],
		description: 'Miscellaneous',
		price: [ 0, 0 ],
		tax: true,
		public: false,
	},
]

export default staticMerch
