import React, { useState, useEffect, useReducer } from 'react'
import { connect } from 'react-redux'
import { makeStyles } from '@material-ui/core/styles';
import moment from 'moment';
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField'
import FormControl from '@material-ui/core/FormControl'
import InputLabel from '@material-ui/core/InputLabel'
import Checkbox from '@material-ui/core/Checkbox';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem'
import FormLabel from '@material-ui/core/FormLabel';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';
import InputAdornment from '@material-ui/core/InputAdornment'
import Divider from '@material-ui/core/Divider';
import Button from '@material-ui/core/Button';
import Autocomplete from '@material-ui/lab/Autocomplete'
import { useParams } from "react-router-dom";
import { getShows, addToCart } from '../actions';

const tshirts = [ '', 'S', 'M', 'L', 'XL', 'XXL']

const useStyles = makeStyles((theme) => ({
  formControl: {
		marginTop: theme.spacing(2),
		marginBottom: theme.spacing(2),
    // minWidth: '50%',
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
	submitButton: {
		marginTop: theme.spacing(2),
		marginBottom: theme.spacing(2),
		[theme.breakpoints.down('sm')]: {
			width: '100%',
		},
		[theme.breakpoints.up('sm')]: {
			minWidth: '50%',
		}
	}
}));

const SubtypeInfo = props => {
	const { selected, subscriptions, radiothon } = props
	const { subtypes } = subscriptions
  let pos = subtypes.map( e => ( e.id )).indexOf(selected)
  let subtype = subtypes[pos]
  return (
    <p>
      {subtype.contents && <span>Pack Contents: <strong>{subtype.contents}</strong><br/></span> }
      {radiothon && subtype.special && <span><strong>Special {subtype.name} Sub Prize draw</strong><br/></span> }
      Cost: <strong>$ {subtype.cost}</strong>
    </p>
  )
}


const Subscription = (props) => {

		let { id } = useParams();
		const { dispatch, subscriptions, initState } = props
		const { subtypes, aprilAtonementPrizes, goldprizes, generalprizes, dailyprizes, shows, radiothon, aprilAtonement, monthly, monthlyPrizes } = subscriptions


		// const { subtypes, goldprizes, dailyprizes, shows } = subscriptions
		// console.log('no daily prizes usage', dailyprizes)
		const classes = useStyles();

	  let pos = subtypes.map( e => ( e.id )).indexOf(id)
	  let subState = subtypes[pos]
		if(subState === undefined) {
			subState = initState
		}

		const dailyprize = subState.daily ? dailyprizes.find( (i) => ( i.includes( moment().format("dddd MMMM D").toUpperCase() ) ) ) : ''
		
		useEffect(() => {
			if(shows.length === 0) {
				dispatch(getShows())
			}
		})

		if (subState.id === 'full' || subState.id === 'concession' || subState.id === 'passionate' ) {
			subState.type = 'general'
		} else {
			subState.type = subState.id
		}

		const addSub = () => {
			if (subState.id === 'full' || subState.id === 'concession' || subState.id === 'passionate' || subState.id === 'super' || subState.id === 'under18') {
				let error = false
				if ( nameoncard === '' ) {
					setErrors({ type: 'add', value: 'nameoncard'});
					error = true
				}
				if (error)
					return
			}
			if (subState.id === 'soloartist' || subState.id === 'band' || subState.id === 'pet' || subState.id === 'business') {
				let error = false
				if ( specialname === '' ) {
					setErrors({ type: 'add', value: 'specialname'});
					error = true
				}
				if (error)
					return
			}

			let params = { nameoncard: nameoncard, email: email, phone: phone, goldprize: goldprize, generalprize: generalprize, monthlyPrize: monthlyPrize, dailyprize: dailyprize, aprilAtonementPrize: aprilAtonementPrize, tshirtsize: tshirtsize, postpack: postpack, specialname: specialname, favshow: favshow.programname, members: members }
			let completeSub = { ...subState, ...params, price: [ subState.cost  + (postpack && postpack === "true" ? 10 : 0 ), subState.cost + (postpack && postpack === "true" ? 10 : 0 ) ], type: 'subscription' }
			reinit()
			dispatch(addToCart(completeSub, params, 1))
		}

		const [nameoncard, setNameoncard] = useState('');
		const [goldprize, setGoldprize] = useState('');
		const [generalprize, setGeneralprize] = useState('');
		const [aprilAtonementPrize, setAprilAtonementPrize] = useState('');
		const [monthlyPrize, setMonthlyPrize] = useState('');
		const [email, setEmail] = useState('');
		const [phone, setPhone] = useState('');
		const [individual, setIndividual] = useState(false);
		const [tshirtsize, setTshirtsize] = useState('');
		const [postpack, setPostpack] = useState(false);
		const [specialname, setSpecialname] = useState('');
		const [favshow, setFavshow] = useState({ active: true, programdate: null, programid: 1, programname: "All Zed Shows", programtime: null});
		const [members, setMembers] = useReducer((members, { type, value, index }) => {
			switch (type) {
				case "init":
					return ['', '', '', '']
				case "add":
					return [...members, value];
				case "remove":
					return members.filter((_, index) => index !== value);
				case "edit":
					let mem = members.slice();
					mem[index] = value;
					return mem;
				default:
					return members;
			}
		}, ['', '','', '']);
		const [errors, setErrors] = useReducer((errors, { type, value }) => {
			switch (type) {
				case "add":
					return { ...errors, [value]: true  };
				case "remove":
					return delete errors[value];
				default:
					return
			}
		}, {});

		const reinit = () => {
			setNameoncard('')
			setEmail('')
			setPhone('')
			setAprilAtonementPrize('')
			setMonthlyPrize('')
			setGoldprize('')
			setGeneralprize('')
			setTshirtsize('')
			setPostpack(false)
			setSpecialname('')
			setFavshow({ active: true, programdate: null, programid: 1, programname: "All Zed Shows", programtime: null})
			setMembers({type: 'init'})
		}

		useEffect( () => {
			if ( nameoncard !== '' ) {
				setErrors({ type: 'remove', value: 'nameoncard' });
			}
		}, [nameoncard])

		useEffect( () => {
			if ( specialname !== '' ) {
				setErrors({ type: 'remove', value: 'specialname' });
			}
		}, [specialname])


		let currentSub = {subtype: id}

    return(
			<>
      <Grid container >
        <Grid item xs={8} sm={8} lg={8} xl={8}>
          <Typography variant="h5">{subState.name}</Typography>
        </Grid>
        <Grid item xs={4} sm={4} lg={4} xl={4}>
          <Typography variant="h5" align="right">
          $ {subState.cost + (postpack && postpack === "true" ? 10 : 0 )} </Typography>
        </Grid>
        <Grid item xs={12} sm={12} lg={12} xl={12}>
				<img src={subState.image} alt="subscription card" style={{width: '100%', height: 'auto'}} />
				{ currentSub.subtype && <SubtypeInfo subscriptions={props.subscriptions} selected={currentSub.subtype} radiothon={radiothon} /> }

          <div>
          { (subState.id === 'full' || subState.id === 'concession' || subState.id === 'passionate' || subState.id === 'super' || subState.id === 'under18') && (
						<>
						<FormControlLabel
						        control={
						          <Checkbox
						            checked={individual}
						            onChange={() => setIndividual(!individual)}
						            name="checkedB"
						            color="primary"
						          />
						        }
						        label="This subscription is for someone else"
					      />

            {individual ?
							<Typography variant="caption" component="div">Please add the subscribers contact information as best you can, if fields are left blank your checkout details will be used.</Typography>
							: null }
						<TextField
							className={classes.formControl}
							id="nameoncard"
							label="Name for sub card"
							value={nameoncard}
							onChange={ (event) => setNameoncard(event.target.value)}
							fullWidth
							error={errors.nameoncard}
							helperText={ errors.nameoncard ? 'Required' : '' }
							margin="normal"
						/>
						{individual ? <>
						<TextField
							className={classes.formControl}
              id="email"
              label="Email"
              value={email}
              onChange={ (event) => setEmail(event.target.value)}
              fullWidth
              margin="normal"
							helperText="Email is used to login, access subscriber benefits and receive Zedletter"
            /><TextField
							className={classes.formControl}
              id="phone"
              label="Phone number"
              value={phone}
              onChange={ (event) => setPhone(event.target.value)}
              fullWidth
              margin="normal"
							helperText="Mobile phone number allows text requests and access to giveaways"
            /><Divider /> </>: null }</>
          )}
					{ monthly && subState.monthly && (
            <FormControl
							className={classes.formControl}
							fullWidth>
              <InputLabel htmlFor="aprilatonementprizedraw">Monthly Prize Draw</InputLabel>
              <Select
                value={monthlyPrize}
                onChange={(event) => setMonthlyPrize(event.target.value)}
                inputProps={{
                  name: 'monthlyprizedraw',
                  id: 'monthlyprizedraw',
                }}
              >
                { monthlyPrizes.map( (item, i) => ( <MenuItem key={i} value={item}>{item}</MenuItem> ))}
              </Select>
							{ monthlyPrize === 'Enter me in the monthly sub prize draw!' ? <React.Fragment><Typography color="primary" variant="caption" component="div">Selecting to enter the prize draw means you agree to our <a href="https://4zzz.org.au/giveaways-and-prize-draw-tcs" target="_blank"> Giveaways & Prize Draw Terms & Conditions</a>. <a style={{textDecoration: 'underline', cursor: 'pointer', fontStyle: 'italic'}} onClick={ () => setMonthlyPrize('No prize thanks, just that warm inner glow')}>I don't agree.</a></Typography><Typography variant="caption" component="div">See <a href="https://4zzz.org.au/monthly-subscriber-prize-draw" target="_blank">here</a> for details of the current prize.</Typography></React.Fragment>  :
							<Typography variant="caption" component="div">Choosing to enter the draw will put your name in the running for <a href="https://4zzz.org.au/monthly-subscriber-prize-draw" target="_blank">this month’s prize.</a> <span style={{fontStyle: 'italic'}}>See our <a href="https://4zzz.org.au/giveaways-and-prize-draw-tcs" target="_blank">T&Cs</a>.</span></Typography> }
            </FormControl>
          ) }
					{ aprilAtonement && subState.gold && (
            <FormControl
							className={classes.formControl}
							fullWidth>
              <InputLabel htmlFor="aprilatonementprizedraw">April Atonement Prize Draw</InputLabel>
              <Select
                value={aprilAtonementPrize}
                onChange={(event) => setAprilAtonementPrize(event.target.value)}
                inputProps={{
                  name: 'aprilatonementprizedraw',
                  id: 'aprilatonementprizedraw',
                }}
              >
                { aprilAtonementPrizes.map( (item, i) => ( <MenuItem key={i} value={item}>{item}</MenuItem> ))}
              </Select>
            </FormControl>
          ) }
					{ aprilAtonement && !subState.gold && (
            <div style={{ padding: '1em 0'}}><strong>All {subState.name} subscriptions go into the draw for a special April Atonement prize.</strong></div>
          ) }
          { radiothon && subState.gold && (
            <FormControl
							className={classes.formControl}
							fullWidth>
              <InputLabel htmlFor="goldprizedraw">Radiothon Prize Draw</InputLabel>
              <Select
                value={goldprize}
                onChange={(event) => setGoldprize(event.target.value)}
                inputProps={{
                  name: 'goldprizedraw',
                  id: 'goldprizedraw',
                }}
              >
                { goldprizes.filter( item => { 
									if ( subState.type === 'under18') { 
										return (item['under18']) 
									}
									else if ( subState.type === 'business') { 
										return (item['business']) 
									} 
									else if ( subState.type === 'super') { 
										return (item['super'] || item['general'] ) 
									} 
									else { 
										return item[subState.type] 
									}  }).map( (item, i) => ( 
										<MenuItem key={i} value={item.prize}>
											{item.super ? <strong>{item.prize}</strong> : ( (item.soloartist && !item.band) || ( item.band && !item.soloartist )) ? <strong>{item.prize}</strong> : <span>{item.prize}</span> }
										</MenuItem> 
									))}
              </Select>
            </FormControl>
          ) }

					{/* { radiothon && subState.general && (
            <FormControl
							className={classes.formControl}
							fullWidth>
              <InputLabel htmlFor="generalprizedraw">General Prize Draw</InputLabel>
              <Select
                value={generalprize}
                onChange={(event) => setGeneralprize(event.target.value)}
                inputProps={{
                  name: 'generalprizedraw',
                  id: 'generalprizedraw',
                }}
              >
                { generalprizes.filter( item => { 
									if ( subState.type === 'super') { 
										return (item['super'] || item['general'] ) 
									} 
									else if ( subState.type === 'business') { 
										return item['business'] 
									}
									else if ( subState.type === 'under18') { 
										return item['under18'] 
									} 
									else if (subState.general) {
										return ( item[subState.type] || item['general'] )
									}
									else { 
										return item[subState.type] 
									}  }).map( (item, i) => ( 
										<MenuItem key={i} value={item.prize}>
											{item.general === false ? <strong>{item.prize}</strong> : <span>{item.prize}</span> }
										</MenuItem> 
									))}
              </Select>
            </FormControl>
          ) } */}

          { radiothon && subState.daily && (
            <TextField
							className={classes.formControl}
              id="dailyprize"
              label="Daily Prize"
              value={dailyprize}
              fullWidth
              disabled
              margin="normal"
            />
          ) }
          {subState.tshirt && (
            <FormControl
							className={classes.formControl}
							fullWidth>
              <InputLabel htmlFor="tShirtSize">T-Shirt Size</InputLabel>
              <Select
                value={tshirtsize} displayEmpty={true}
                onChange={(event) => setTshirtsize(event.target.value)}
                inputProps={{
                  name: 'tshirtsize',
                  id: 'tShirtSize',
                }}
              >
                { tshirts.map( (item, i) => ( <MenuItem key={i} value={item}>{item}</MenuItem> ))}
              </Select>
            </FormControl>
          )}
          {subState.pack === 'disabled' && (
            <FormControl component="fieldset">
            <FormLabel>Sub Pack</FormLabel>
            <RadioGroup
              aria-label="Sub Pack"
              name="postpack"
              value={postpack}
              onChange={(event) => setPostpack(event.target.value)}
            >
              <FormControlLabel value="false" control={<Radio />} label="Pick up sub pack - no charge" />
              <FormControlLabel value="true" control={<Radio />} label="Post sub pack + $10" />
            </RadioGroup>
          </FormControl>
          )}
          { (subState.id === 'soloartist' || subState.id === 'band' || subState.id === 'pet' || subState.id === 'business') && (
            <TextField
							className={classes.formControl}
              id="specialname"
              label={subState.name + ' Name'}
              value={specialname}
              onChange={(event) => setSpecialname(event.target.value)}
              fullWidth
							error={errors.specialname}
							helperText={ errors.specialname ? 'Required' : '' }
              margin="normal"
            />
          )}
          { (subState.id === 'household' || subState.id === 'band') && (
            members.map( (member, i) => (
              <TextField
								className={classes.formControl}
                key={i} id="member"
                label={subState.name + ' Member'}
                value={member}
                onChange={event => { setMembers( {type: 'edit', value:  event.target.value, index: i} )}}
                fullWidth
                margin="normal"
                InputProps={{
                  startAdornment: <InputAdornment position="start">#{i + 1}</InputAdornment>,
                }}
              />
            ))
          )}
					{ (subState.id !== 'pay-it-forward') && 
					<Autocomplete
						id="favourite-shows"
						options={shows}
						value={favshow}
						onChange={ (event, show) => { setFavshow(show) }}
						getOptionLabel={(option) => option.programname}
						getOptionSelected={(option, value) => { return ( option.programname === value.programname )} }
						renderInput={(params) => <TextField
							className={classes.formControl}
							{...params} fullWidth label="Favourite 4ZZZ or Zed Digital Program" />}
					/>
					}
					{ (subState.id === 'pay-it-forward') &&
						<div><h4>Share the warm inner glow around.</h4> <p>Pay it Forward subscriptions are available to anyone in financial hardship or who is currently incarcerated. This helps people who can't afford a subscription become a part of our community and volunteer at the station.</p><p><a target="_blank" href="https://4zzz.org.au/pay-it-forward">More information can be found on our website</a></p><p><em>Pay it Forward subscription purchases are not tax-deductible.</em></p></div>
					}
					


          </div>
        </Grid>

      </Grid>
      {/* <Divider  style={{ marginBottom: '4em'}} /> */}

		  <Grid style={{marginTop: '2em'}}>
		  	<Button size="large" disabled={false} variant="contained" color="secondary" aria-label="Add" onClick={addSub}
					className={classes.submitButton}
					>
		    	Add to cart
		  	</Button>
		  	{props.debug && <Button onClick={props.sendSubs(props.subscriptions, props.contact)}>TEST SEND</Button>}
		  </Grid>
		</>
	)
}

export default connect( state => ({ subscriptions: state.subscriptions,  cart: state.cart })) (Subscription)
